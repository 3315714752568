'use client'

import { Button, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import Link from 'next/link'
import { useState } from 'react'
import { useCookieConsentState } from 'state/cookie-consent'
import ManageCookieModal from './ManageCookieModal'

export default function CookieConsent() {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { accept } = useCookieConsentState()
  return (
    <>
      <VStack
        py={10}
        px={{ base: '2rem', md: '4rem', xl: '6rem' }}
        shadow="0px 1px 38px 0px #00000040"
        position="fixed"
        bottom={0}
        left={0}
        bg="white"
        align="start"
        gap={4}
        zIndex="banner"
        textUnderlineOffset="4px">
        <Heading>Can we use optional cookies?</Heading>
        <Text>
          This website uses cookies to enhance your experience and help us understand how you engage
          with the site. Any data collected through cookies is secure and handled in accordance with
          our privacy policy. You can view our{' '}
          <Link href="/privacy-policy">
            <Text as="u">Privacy Policy here.</Text>
          </Link>
        </Text>

        <HStack gap={3}>
          <Button
            borderRadius="full"
            border="2px solid"
            borderColor="brand.primary"
            bg="brand.primary"
            fontWeight="semibold"
            py="20px"
            px="20px"
            sx={{
              ':hover': {
                opacity: 0.8
              }
            }}
            onClick={accept}>
            Accept
          </Button>
          <Button
            borderRadius="full"
            bg="transparent"
            border="2px solid"
            borderColor="brand.primary"
            color="brand.primary"
            py="20px"
            px="20px"
            sx={{
              ':hover': {
                bg: 'brand.primary',
                color: 'white'
              }
            }}
            onClick={() => setIsOpenModal(true)}>
            Manage Cookies
          </Button>
        </HStack>
      </VStack>
      <ManageCookieModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </>
  )
}
