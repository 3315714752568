import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Button,
  Text,
  HStack,
  Stack,
  Icon,
  Checkbox,
  FormLabel
} from '@chakra-ui/react'
import '/styles/modal.css'
import { Dispatch, SetStateAction, useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { useCookieConsentState } from 'state/cookie-consent'
import Link from 'next/link'

export default function ManageCookieModal({
  isOpenModal,
  setIsOpenModal
}: {
  isOpenModal: boolean
  setIsOpenModal: Dispatch<SetStateAction<boolean>>
}) {
  const { accept } = useCookieConsentState()

  const close = () => {
    setIsOpenModal(false)
  }

  return (
    <>
      <Modal isOpen={isOpenModal} onClose={close} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <Box mt="8px">
            <ModalCloseButton fontSize="16px" />
          </Box>
          <ModalBody>
            <Stack
              py={10}
              px={3}
              bg="white"
              align="start"
              gap={6}
              zIndex="banner"
              textUnderlineOffset="4px">
              <Heading>Customize your cookie settings</Heading>
              <Text fontWeight="medium">
                This website uses cookies to enhance your experience and help us understand how you
                engage with the site. Any data collected through cookies is secure and handled in
                accordance with our privacy policy. You can view our{' '}
                <Link href="/privacy-policy">
                  <Text as="u">Privacy Policy here.</Text>
                </Link>
              </Text>

              <Stack gap={3} w="full">
                <CookieSetting
                  text="Analytics Cookies"
                  description="This helps us know where you come from and how you use our site, so we can make it even better for you."
                />
                <CookieSetting
                  text="Required Cookies"
                  description="This cookie is a must for our website to work properly and keep you safe."
                  defaultChecked
                />
              </Stack>

              <HStack gap={3}>
                <Button
                  borderRadius="full"
                  border="2px solid"
                  borderColor="brand.primary"
                  bg="brand.primary"
                  fontWeight="semibold"
                  py="20px"
                  px="20px"
                  sx={{
                    ':hover': {
                      opacity: 0.8
                    }
                  }}
                  onClick={accept}>
                  Save
                </Button>
                <Button
                  borderRadius="full"
                  bg="transparent"
                  border="2px solid"
                  borderColor="brand.primary"
                  color="brand.primary"
                  py="20px"
                  px="20px"
                  sx={{
                    ':hover': {
                      bg: 'brand.primary',
                      color: 'white'
                    }
                  }}
                  onClick={accept}>
                  Accept all cookies
                </Button>
              </HStack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

function CookieSetting({
  text,
  defaultChecked = false,
  description
}: {
  text: string
  defaultChecked?: boolean
  description: string
}) {
  const [show, setShow] = useState(false)

  return (
    <Stack gap="12px" p="1.6rem" border="1px" borderColor="brand.primary" borderRadius="12px">
      <HStack align="center" w="full">
        <HStack align="center" w="full">
          <FormLabel>
            <HStack gap={2} align="center">
              <Checkbox
                size="lg"
                sx={{ h: '20px' }}
                defaultChecked={defaultChecked}
                isDisabled={defaultChecked}
              />
              <Heading fontSize="16px">{text}</Heading>
            </HStack>
          </FormLabel>
        </HStack>

        <Icon
          as={FaChevronDown}
          fontSize="20px"
          cursor="pointer"
          transition="ease-in"
          transform={show ? 'rotate(180deg)' : 'rotate(0deg)'}
          onClick={() => setShow(!show)}
        />
      </HStack>

      {show && <Text>{description}</Text>}
    </Stack>
  )
}
