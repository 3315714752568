'use client'

import { Box, useMediaQuery } from '@chakra-ui/react'
import { Hero } from 'components/home/Hero'
import React, { useEffect, useState } from 'react'
import NavBar from 'components/navbar'
import { colors } from 'styles/foundations'
import {
  UtilityCards,
  NotificationFeature,
  FeaturesStripe,
  PaymentSetups,
  MobileFeatured,
  PlansSection,
  AutofundFeature,
  CustomersComments,
  DownloadApp
} from 'components'
import Footer from 'components/footer'

import { ParallaxProvider } from 'react-scroll-parallax'
import FeatureSection from 'components/home/FeatureSection'
import ScrollToTopButton from 'components/scroll-to-top'
import { useCookieConsentState } from 'state/cookie-consent'
import CookieConsent from 'components/cookies/CookieConsent'

export default function Page() {
  const [isDesktop] = useMediaQuery('(max-width: 1020px)')

  const [hasAccepted, setHasAccepted] = useState(true)
  const { hasAccepted: hasAcceptedState } = useCookieConsentState()

  useEffect(() => {
    const cookieConsentValue = localStorage.getItem('cookieConsent')
    if (cookieConsentValue) {
      const parsedValue = JSON.parse(cookieConsentValue)
      setHasAccepted(parsedValue.state.hasAccepted)
    }
  }, [hasAcceptedState])

  return (
    <>
      <Box bg={colors.brand.white} w="full">
        <ParallaxProvider>
          <ScrollToTopButton />
          <NavBar />
          <Hero />
          <FeaturesStripe />
          <PlansSection />
          <DownloadApp />
          <PaymentSetups />
          {isDesktop ? <MobileFeatured /> : <FeatureSection />}
          <Box>
            <NotificationFeature />
          </Box>
          {/* <ScrollComponent /> */}
          <CustomersComments />
          <AutofundFeature />
          <UtilityCards />
          <Footer />
        </ParallaxProvider>
      </Box>
      {!hasAccepted && <CookieConsent />}
    </>
  )
}

// export default Page
