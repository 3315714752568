import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface CookieConsent {
  hasAccepted: boolean
  accept: () => void
}

export const useCookieConsentState = create<CookieConsent>()(
  persist(
    (set) => ({
      hasAccepted: false,
      accept: () => {
        set(() => {
          return { hasAccepted: true }
        })
      }
    }),
    { name: 'cookieConsent' }
  )
)
